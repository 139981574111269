
export default {
  namespaced: true,
  state: {
    avatar: null,
    role: '',
    permissions: []
  },
  getters: {
    getAvatar () {
        return state.avatar
      },
      getPermissions(){
        return state.permissions
      }
  },
  mutations: {
    USER_AVATAR(state, val) {
        state.avatar = val
    },
    SET_ROLE(state,role) {
      state.role = role;
    },
    SET_USER_PERMISSIONS(state,permissions_obj) {
      var permissions_arr = []

      if( permissions_obj.length == 0){
        state.permissions = []
      }
      else{
        for (let i = 0; i < permissions_obj.length; i++) {
          permissions_arr.push(permissions_obj[i]['name'])
       }
       state.permissions = permissions_arr;
      }

    }
  },
  actions: {},
}





export const mutations = {
  setRole(role) {
    store.role = role;
  },
  setDisallowedRoutes(routes) {
    store.disallowedRoutes = routes;
  }
};