import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionApi from '@vue/composition-api'
import VueLazyload from 'vue-lazyload'

import router from './router'
import store from './store'
import App from './App.vue'


// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import User from './Helpers/User'
import mixin from './mixin'


import '@/@fake-db/db'
//import acl from './router/acl'

window.mixin = mixin

window.User = User

window.EventBus = new Vue();

Vue.directive('lazy-container', {
});

// Vue.component('pagination', require('laravel-vue-pagination'));

Vue.use(VueLazyload)

// or with options
const loadimage = require('./assets/images/gifs/lazy.gif')
const errorimage = require('./assets/images/gifs/eroimg.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionApi)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


