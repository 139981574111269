import Vue from 'vue'

Vue.mixin({
    methods: {
      savePerPage:perPage =>{
        localStorage.setItem('perPage',perPage)
      },
      getPerPage:()=>{
        return localStorage.getItem('perPage')
      }
    },
  })

