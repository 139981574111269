class AppStorage{

    storeToken(token){
        localStorage.setItem('token',token);
    }

    storeUser(username){
        localStorage.setItem('username',username);
    }

    storeUserId(userid){
        localStorage.setItem('userid',userid);
    }

    storeUserRole(userrole){
        localStorage.setItem('userrole',userrole)
    }

    storeUserAvatar(useravatar){
        localStorage.setItem('useravatar',useravatar)
    }

    store(username,token,userid,userrole,useravatar){
        this.storeToken(token)
        this.storeUser(username)
        this.storeUserId(userid)
        this.storeUserRole(userrole)
        this.storeUserAvatar(useravatar)
    }

    clear(){
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('userid')
        localStorage.removeItem('userrole')
        localStorage.removeItem('useravatar')
        localStorage.removeItem('user_permisions')
    }

    getToken(){
       return localStorage.getItem('token')
    }

    getUserName(){
        return localStorage.getItem('username')
    }

    getUserId(){
        return localStorage.getItem('userid')
    }

    getUserRole(){
        return localStorage.getItem('userrole')
    }

    getUserAvatar(){
        return localStorage.getItem('useravatar')
    }
}

export default AppStorage = new AppStorage();